footer {
  background: black;
  bottom: 0;
  font-size: 0.7rem;
  left: 0;
  letter-spacing: 2px;
  position: fixed;
  right: 0;
  width: 100%;
  color: white;
  text-align: center;
}

footer a {
  color: white;
}