.bgimage {
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  left: 0;
  top: 0;
  display: block;
  object-fit: cover;
}