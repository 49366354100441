.container {
  color: #333;
  margin: 0 auto;
}

h1 {
  font-weight: bold;
  font-size: 2.3em;
  letter-spacing: .125rem;
  text-transform: uppercase;
  color: white;
  text-align: center;
}

p {
  font-weight: normal;
  font-size: 1em;
  letter-spacing: .125rem;
  text-transform: uppercase;
  color: white;
  text-align: center;
  padding-bottom: 10px;
}

ul {
  margin: auto 50px;
  text-align: center;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  color: white;
  text-align: center;
}

li span {
  display: block;
  font-size: 4.5rem;
  color: white;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media all and (max-width: 900px) {
  h1 {
    font-size: 1.5rem;
  }
  
  li {
    font-size: 1.125rem;
    padding: .35rem;
  }
  
  li span {
    font-size: 3.375rem;
  }
}